"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ASSET_CONSTRAINTS_SHORT = exports.ASSET_CONSTRAINTS = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _apiService = _interopRequireWildcard(require("./api-service"));

var _imageService = require("~/app/3x/modules/services/image-service");

var _validationService = _interopRequireDefault(require("./validation-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var CREATE_ASSET_MUTATION = "\nmutation createAsset($input: AssetInput) {\n\tcreateAsset(input: $input) {\n\t\tasset {\n\t\t\tid\n\t\t\tname\n\t\t\tembargo\n\t\t}\n\t}\n}\n";
var ASSET_CONSTRAINTS = {
  name: {
    presence: {
      allowEmpty: false
    }
  },
  slug: {
    presence: {
      allowEmpty: false
    },
    format: {
      pattern: /^[a-zA-Z0-9-]+$/
    }
  },
  usage: {
    presence: {
      allowEmpty: false
    }
  },
  type: {
    presence: {
      allowEmpty: false
    }
  }
};
exports.ASSET_CONSTRAINTS = ASSET_CONSTRAINTS;
var ASSET_CONSTRAINTS_SHORT = {
  name: {
    presence: {
      allowEmpty: false
    }
  },
  usage: {
    presence: {
      allowEmpty: false
    }
  },
  type: {
    presence: {
      allowEmpty: false
    }
  }
};
exports.ASSET_CONSTRAINTS_SHORT = ASSET_CONSTRAINTS_SHORT;
var AssetService = {
  createAsset(asset) {
    var params = {
      input: _objectSpread(_objectSpread({}, asset), asset.length ? {
        length: parseInt(asset.length, 10)
      } : {})
    };
    return _apiService.default.graphql.request(CREATE_ASSET_MUTATION, params);
  },

  validateAsset(asset) {
    return _validationService.default.validate(asset, ASSET_CONSTRAINTS);
  },

  validateAssetShort(asset) {
    return _validationService.default.validate(asset, ASSET_CONSTRAINTS_SHORT);
  },

  addAssetAudio(data) {
    return _apiService.default.client.post('/asset-audios', data).then(res => res.data);
  },

  deleteAssetAudio(id) {
    return _apiService.default.client.delete("/asset-audios/".concat(id)).then(res => res.data);
  },

  getAssetAudiosList(id) {
    return _apiService.default.client.get("/asset-audios?asset=".concat(id)).then(res => res.data);
  },

  // TODO fix autosubs variable - have a single value from the backend
  canAutosub(asset) {
    return (0, _lodash2.default)(asset, '$permissions.canAutosub', false) && (0, _lodash2.default)(asset, '$permissions.access', []).includes('autosubs') || (0, _lodash2.default)(asset, 'permissions.access', []).includes('autosubs');
  },

  canOrder(asset) {
    var _asset$$permissions, _asset$permissions;

    // @todo remove $permissions
    return !asset.unorderable && !asset.creative && ((asset === null || asset === void 0 ? void 0 : (_asset$$permissions = asset.$permissions) === null || _asset$$permissions === void 0 ? void 0 : _asset$$permissions.access.includes('requests')) || (asset === null || asset === void 0 ? void 0 : (_asset$permissions = asset.permissions) === null || _asset$permissions === void 0 ? void 0 : _asset$permissions.access.includes('requests')));
  },

  canShare(asset) {
    var _asset$$permissions2, _asset$permissions2;

    // @todo remove $permissions
    return (asset === null || asset === void 0 ? void 0 : (_asset$$permissions2 = asset.$permissions) === null || _asset$$permissions2 === void 0 ? void 0 : _asset$$permissions2.access.includes('sharing')) || (asset === null || asset === void 0 ? void 0 : (_asset$permissions2 = asset.permissions) === null || _asset$permissions2 === void 0 ? void 0 : _asset$permissions2.access.includes('sharing'));
  },

  canOrderLanguage(asset, language) {
    var withAutosubs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var key = withAutosubs ? 'autosubs' : 'requests';
    var access = (0, _lodash2.default)(asset, '$permissions.access', []);
    var canAccess = access.indexOf(key) !== -1;

    if (!canAccess) {
      return false;
    }

    var canOrderLanguages = (0, _lodash2.default)(asset, "$permissions.sources.".concat(key, ".languages"));

    if (!_lodash.default.isArray(canOrderLanguages)) {
      return false;
    }

    var canOrder = !canOrderLanguages.length || canOrderLanguages.indexOf(language.code) !== -1;
    return canOrder;
  },

  canOrderTerritory(asset, territory) {
    var withAutosubs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var key = withAutosubs ? 'autosubs' : 'requests';
    var access = (0, _lodash2.default)(asset, '$permissions.access', []);
    var canAccess = access.indexOf(key) !== -1;

    if (!canAccess) {
      return false;
    }

    var canOrderTerritories = (0, _lodash2.default)(asset, "$permissions.sources.".concat(key, ".countries"));

    if (!_lodash.default.isArray(canOrderTerritories)) {
      return false;
    }

    var canOrder = !canOrderTerritories.length || canOrderTerritories.indexOf(territory.code) !== -1;
    return canOrder;
  },

  getProjectAssets(projectId) {
    return _apiService.default.client.get("/assets?project=".concat(projectId), {
      headers: {
        'X-Range': 'resources=0-500'
      }
    }).then(res => res.data);
  },

  getProjectAssetsAllGraphql(QUERY, projectId, params) {
    return (0, _asyncToGenerator2.default)(function* () {
      var assets = [];
      var limit = 10;
      var offset = 0;
      var hasMore = true;

      while (hasMore) {
        try {
          // Create up to 5 query requests
          var queries = [];

          for (var i = 0; i < 5; i++) {
            var queryParams = _objectSpread({
              id: projectId,
              limit: limit,
              offset: offset
            }, params);

            queries.push(_apiService.default.graphql.request(QUERY, queryParams));
            offset += limit; // Increment the offset for the next query
          } // Execute all 5 queries in parallel


          var results = yield Promise.all(queries); // Track the total assets fetched in this batch

          var batchFetchedCount = 0;
          results.forEach(res => {
            var _res$assets;

            var fetchedAssets = ((_res$assets = res.assets) === null || _res$assets === void 0 ? void 0 : _res$assets.nodes) || [];

            if (fetchedAssets.length > 0) {
              assets.push(...fetchedAssets);
            }

            batchFetchedCount += fetchedAssets.length;
          }); // Stop if no assets were fetched in this batch

          if (batchFetchedCount === 0) {
            hasMore = false;
          }
        } catch (error) {
          console.error('Error fetching assets:', error); // Optionally handle retries or break the loop in case of failure

          hasMore = false;
        }
      }

      return assets;
    })();
  },

  getProjectAssetsAll(projectId, params) {
    return (0, _asyncToGenerator2.default)(function* () {
      var assets = [];
      var limit = 50;
      var start = 0;
      var hasMore = true;
      var maxParallelQueries = 5;

      while (hasMore) {
        try {
          // Create up to 5 parallel queries
          var queries = [];

          for (var i = 0; i < maxParallelQueries; i++) {
            var url = (0, _apiService.buildURL)("/assets", _objectSpread({
              project: projectId
            }, params));
            queries.push(_apiService.default.client.get(url, {
              headers: {
                'X-Range': "resources=".concat(start, "-").concat(start + limit - 1)
              }
            }));
            start += limit; // Increment the start for each new query
          } // Execute all queries in parallel


          var results = yield Promise.all(queries); // Track the total assets fetched in this batch

          var batchFetchedCount = 0;
          results.forEach(res => {
            var fetchedAssets = res.data || [];

            if (fetchedAssets.length > 0) {
              assets.push(...fetchedAssets);
            }

            batchFetchedCount += fetchedAssets.length;
          }); // Stop if no more assets were fetched in this batch

          if (batchFetchedCount < limit * maxParallelQueries) {
            hasMore = false;
          }
        } catch (error) {
          console.error('Error fetching assets:', error); // Optionally handle retries or stop the loop in case of failure

          hasMore = false;
        }
      }

      return assets;
    })();
  },

  getProjectAssetsWithSearch(projectId, q) {
    return _apiService.default.client.get((0, _apiService.buildURL)("/assets", {
      project: projectId,
      q
    }), {
      headers: {
        'X-Range': 'resources=0-500'
      }
    }).then(res => res.data);
  },

  getAllAssetsList(params) {
    if (!params.project) {
      delete params.project;
    }

    return _apiService.default.client.get((0, _apiService.buildURL)("/lists/assets", params), {
      headers: {
        'X-Range': 'resources=0-500'
      }
    }).then(res => res.data);
  },

  getSimilarAssets(params) {
    var id = params.project;
    delete params.project;
    return this.getProjectAssetsAll(id, params);
  },

  getAssetById(id) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var url = options.withExtended ? "/assets/".concat(id, "?$permissions=extended") : "/assets/".concat(id);
    return _apiService.default.client.get(url).then(res => res.data[0] || res.data);
  },

  getRelatedAssets(assetId) {
    return _apiService.default.client.get("/assets/related/".concat(assetId)).then(res => res.data || []);
  },

  getResizedThumbnail(asset) {
    var _asset$$links$thumbna, _asset$$links;

    // @todo remove $links when GraphQL is used everywhere
    var thumbnailUrl = (_asset$$links$thumbna = asset === null || asset === void 0 ? void 0 : (_asset$$links = asset.$links) === null || _asset$$links === void 0 ? void 0 : _asset$$links.thumbnail) !== null && _asset$$links$thumbna !== void 0 ? _asset$$links$thumbna : asset === null || asset === void 0 ? void 0 : asset.thumbnail;

    if (!thumbnailUrl) {
      return '//via.placeholder.com/160x67?text=No%20Thumbnail';
    }

    return (0, _imageService.cdn)(thumbnailUrl, {
      width: 160
    });
  },

  getLength(asset) {
    var length = asset.length;

    if (!length) {
      return null;
    }

    var minutes = ~~(length / 60);
    var seconds = length - minutes * 60;
    var minutesStr = ('' + minutes).padStart(2, '0');
    var secondsStr = ('' + seconds).padStart(2, '0');
    return "".concat(minutesStr, ":").concat(secondsStr);
  },

  isNew(asset) {
    var _asset$flags, _asset$$flags;

    return (asset === null || asset === void 0 ? void 0 : (_asset$flags = asset.flags) === null || _asset$flags === void 0 ? void 0 : _asset$flags.includes('new')) || (asset === null || asset === void 0 ? void 0 : (_asset$$flags = asset.$flags) === null || _asset$$flags === void 0 ? void 0 : _asset$$flags.includes('new'));
  },

  isRecentlyUpdated(asset) {
    var _asset$flags2, _asset$$flags2;

    return (asset === null || asset === void 0 ? void 0 : (_asset$flags2 = asset.flags) === null || _asset$flags2 === void 0 ? void 0 : _asset$flags2.includes('updated')) || (asset === null || asset === void 0 ? void 0 : (_asset$$flags2 = asset.$flags) === null || _asset$$flags2 === void 0 ? void 0 : _asset$$flags2.includes('updated'));
  },

  isPrintAsset(asset) {
    return asset.mediaType === 'image';
  },

  isRoadshowAsset(asset) {
    return asset.type === 'roadshow';
  },

  isTrailerAsset(asset) {
    return asset.type.indexOf('trailer') > -1;
  },

  updateAsset(id, data) {
    return _apiService.default.client.patch("/assets/".concat(id), data).then(res => res.data);
  },

  searchAssets(query) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
    return _apiService.default.client.get((0, _apiService.buildURL)('/assets', {
      q: query,
      limit: limit
    })).then(res => res.data);
  },

  triggerGraphicsAnalysis(id) {
    return _apiService.default.client.get("/assets/analyze?id=".concat(id)).then(res => res.data && res.status === 200);
  },

  deleteAsset(id) {
    return _apiService.default.client.delete("/assets/".concat(id)).then(res => res.data);
  }

};
var _default = AssetService;
exports.default = _default;