"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SEARCH_USERS_QUERY = exports.SEARCH_GROUPS_QUERY = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireWildcard(require("./api-service"));

var _userService = _interopRequireDefault(require("~/app/3x/modules/services/user-service"));

var _lodash = _interopRequireDefault(require("lodash"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var SEARCH_USERS_QUERY = "\nquery searchUsers($q: String) {\n  users(q: $q, limit: 20) {\n    nodes {\n      id\n      name\n      emailNormal\n    }\n  }\n}\n";
exports.SEARCH_USERS_QUERY = SEARCH_USERS_QUERY;
var SEARCH_GROUPS_QUERY = "\nquery searchGroups($q: String) {\n  groups(q: $q, limit: 20) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n";
exports.SEARCH_GROUPS_QUERY = SEARCH_GROUPS_QUERY;
var PermissionService = {
  getUsers: params => {
    return _apiService.default.graphql.request(SEARCH_USERS_QUERY, params !== null && params !== void 0 && params.q ? params : {}).then(response => {
      var _response$users$nodes, _response$users;

      return (_response$users$nodes = response === null || response === void 0 ? void 0 : (_response$users = response.users) === null || _response$users === void 0 ? void 0 : _response$users.nodes) !== null && _response$users$nodes !== void 0 ? _response$users$nodes : [];
    });
  },
  getGroups: params => {
    return _apiService.default.graphql.request(SEARCH_GROUPS_QUERY, params !== null && params !== void 0 && params.q ? params : {}).then(response => {
      var _response$groups$node, _response$groups;

      return (_response$groups$node = response === null || response === void 0 ? void 0 : (_response$groups = response.groups) === null || _response$groups === void 0 ? void 0 : _response$groups.nodes) !== null && _response$groups$node !== void 0 ? _response$groups$node : [];
    });
  },
  getUser: id => {
    return _apiService.default.client.get((0, _apiService.buildURL)('/users', {
      id
    })).then(response => response.data[0]);
  },
  getGroup: id => {
    return _apiService.default.client.get((0, _apiService.buildURL)('/groups', {
      id
    })).then(response => {
      return response.data[0];
    });
  },
  getUserPermissions: user => {
    return _apiService.default.client.get((0, _apiService.buildURL)('/permissions', {
      user: user
    })).then(response => response.data);
  },
  getGroupsPermissions: groups => {
    if (!_lodash.default.isEmpty(groups)) {
      return _apiService.default.client.get((0, _apiService.buildURL)('/permissions', {
        groups: groups
      })).then(response => response.data);
    }
  },
  getShare: id => {
    return _apiService.default.client.get((0, _apiService.buildURL)('/shares', {
      id
    })).then(response => response.data);
  },
  getPermissions: _ref => {
    var {
      users
    } = _ref;
    var params = {
      users
    };

    if (!users) {
      delete params.users;
    }

    return _apiService.default.client.get((0, _apiService.buildURL)('/permissions', params));
  },
  getLanguagesPermissions: _ref2 => {
    var {
      assets
    } = _ref2;
    var params = {
      assets
    };

    if (!assets) {
      delete params.assets;
    }

    return _apiService.default.client.get((0, _apiService.buildURL)('/permissions/languages', params));
  },
  hasIngestPermission: session => {
    if (_userService.default.isAdmin(session)) {
      return true;
    }

    return session.permissions.length ? !!session.permissions.find(permission => {
      var _permission$access;

      return (_permission$access = permission.access) === null || _permission$access === void 0 ? void 0 : _permission$access.ingest;
    }) : false;
  },
  hasReportsPermission: session => {
    if (_userService.default.isAdmin(session)) {
      return true;
    }

    return session.permissions.length ? !!session.permissions.find(permission => {
      return _lodash.default.intersection(Object.keys(permission.access).filter(key => permission.access[key]), ['downloadReport', 'encodeReport', 'exceptionReport', 'feedbackReport', 'groupSpendingReport', 'workRequestReport']).length > 0;
    }) : false;
  },
  deletePermissions: id => {
    return _apiService.default.client.delete("/permissions/".concat(id));
  },
  createPermission: data => {
    return _apiService.default.client.post('/permissions', data);
  },
  updatePermission: (id, data) => {
    return _apiService.default.client.patch("/permissions/".concat(id), data);
  },
  updateSharePermission: (id, data) => {
    return _apiService.default.client.patch("/shares/".concat(id), data);
  },
  transformPermission: (permission, isEditing) => {
    var _permission$sources, _permission$sources2, _permission$sources2$, _permission$sources2$2, _permission$sources3, _permission$sources3$, _permission$sources3$2, _permission$sources4, _permission$sources5, _permission$sources5$, _permission$sources5$2, _permission$sources6, _permission$sources6$, _permission$sources6$2, _transformed$sources, _transformed$sources$, _transformed$sources2, _transformed$sources3, _transformed$sources4, _transformed$sources5, _transformed$sources6, _transformed$sources7;

    var transformed = _objectSpread(_objectSpread({}, permission), {}, {
      sources: _objectSpread(_objectSpread({}, permission.sources), {}, {
        adminWorkRequests: _objectSpread(_objectSpread({}, (_permission$sources = permission.sources) === null || _permission$sources === void 0 ? void 0 : _permission$sources.adminWorkRequests), {}, {
          groups: ((_permission$sources2 = permission.sources) === null || _permission$sources2 === void 0 ? void 0 : (_permission$sources2$ = _permission$sources2.adminWorkRequests) === null || _permission$sources2$ === void 0 ? void 0 : (_permission$sources2$2 = _permission$sources2$.groups) === null || _permission$sources2$2 === void 0 ? void 0 : _permission$sources2$2.map(group => group.id)) || [],
          users: ((_permission$sources3 = permission.sources) === null || _permission$sources3 === void 0 ? void 0 : (_permission$sources3$ = _permission$sources3.adminWorkRequests) === null || _permission$sources3$ === void 0 ? void 0 : (_permission$sources3$2 = _permission$sources3$.users) === null || _permission$sources3$2 === void 0 ? void 0 : _permission$sources3$2.map(user => user.id)) || []
        }),
        manageWorkRequests: _objectSpread(_objectSpread({}, (_permission$sources4 = permission.sources) === null || _permission$sources4 === void 0 ? void 0 : _permission$sources4.manageWorkRequests), {}, {
          groups: ((_permission$sources5 = permission.sources) === null || _permission$sources5 === void 0 ? void 0 : (_permission$sources5$ = _permission$sources5.manageWorkRequests) === null || _permission$sources5$ === void 0 ? void 0 : (_permission$sources5$2 = _permission$sources5$.groups) === null || _permission$sources5$2 === void 0 ? void 0 : _permission$sources5$2.map(group => group.id)) || [],
          users: ((_permission$sources6 = permission.sources) === null || _permission$sources6 === void 0 ? void 0 : (_permission$sources6$ = _permission$sources6.manageWorkRequests) === null || _permission$sources6$ === void 0 ? void 0 : (_permission$sources6$2 = _permission$sources6$.users) === null || _permission$sources6$2 === void 0 ? void 0 : _permission$sources6$2.map(user => user.id)) || []
        })
      })
    });

    if (typeof transformed.project === 'boolean') {
      delete transformed.project;
    } else {
      var _permission$project$v, _permission$project;

      transformed.project = (_permission$project$v = (_permission$project = permission.project) === null || _permission$project === void 0 ? void 0 : _permission$project.value) !== null && _permission$project$v !== void 0 ? _permission$project$v : permission.project;
    }

    if (typeof transformed.studio === 'boolean') {
      delete transformed.studio;
    } else {
      var _permission$studio$va, _permission$studio;

      transformed.studio = (_permission$studio$va = (_permission$studio = permission.studio) === null || _permission$studio === void 0 ? void 0 : _permission$studio.value) !== null && _permission$studio$va !== void 0 ? _permission$studio$va : permission.studio;
    }

    if (typeof transformed.types === 'boolean') {
      transformed.types = [];
    }

    if (!transformed.assets || typeof transformed.assets === 'boolean') {
      transformed.assets = [];
    } else {
      transformed.assets = transformed.assets.map(asset => {
        var _asset$_id;

        return typeof asset === 'object' ? (_asset$_id = asset._id) !== null && _asset$_id !== void 0 ? _asset$_id : asset.value : asset;
      });
    }

    if (isEditing) {
      delete transformed.user;
      delete transformed.approved;
      delete transformed._id;
    } // fields used for FE display


    delete transformed.users;
    delete transformed.groups;
    (_transformed$sources = transformed.sources) === null || _transformed$sources === void 0 ? true : (_transformed$sources$ = _transformed$sources.preview) === null || _transformed$sources$ === void 0 ? true : delete _transformed$sources$.edit;
    (_transformed$sources2 = transformed.sources) === null || _transformed$sources2 === void 0 ? true : (_transformed$sources3 = _transformed$sources2.requests) === null || _transformed$sources3 === void 0 ? true : delete _transformed$sources3.edit;
    (_transformed$sources4 = transformed.sources) === null || _transformed$sources4 === void 0 ? true : (_transformed$sources5 = _transformed$sources4.autosubs) === null || _transformed$sources5 === void 0 ? true : delete _transformed$sources5.edit;
    (_transformed$sources6 = transformed.sources) === null || _transformed$sources6 === void 0 ? true : (_transformed$sources7 = _transformed$sources6.download) === null || _transformed$sources7 === void 0 ? true : delete _transformed$sources7.edit;
    delete transformed.$links;
    delete transformed.created;
    return transformed;
  }
};
var _default = PermissionService;
exports.default = _default;