"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_LAST_ATTEMPT = exports.TWO_FACTOR_LOGIN_SUCCESS = exports.TWO_FACTOR_LOGIN_REQUEST = exports.TWO_FACTOR_LOGIN_FAILURE = exports.TWO_FACTOR_LOGIN = exports.STORE_NAME = exports.RESUME_SESSION = exports.RESET_STORES = exports.RESET_PASSWORD_SUCCESS = exports.RESET_PASSWORD_REQUEST = exports.RESET_PASSWORD_FAILURE = exports.RESET_PASSWORD_CLEAR_FEEDBACK = exports.RESET_PASSWORD = exports.MANUAL_UPDATE_SESSION = exports.LOGOUT_SUCCESS = exports.LOGOUT_REQUEST = exports.LOGOUT_FAILURE = exports.LOGOUT = exports.LOGIN_SUCCESS = exports.LOGIN_REQUEST = exports.LOGIN_FAILURE = exports.LOGIN = exports.FETCH_SESSION_SUCCESS = exports.FETCH_SESSION_REQUEST = exports.FETCH_SESSION_FAILURE = exports.FETCH_SESSION = exports.CLEAR_SESSION = void 0;
exports.fetchSession = fetchSession;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchSession = watchSession;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _apiService = require("../modules/services/api-service");

var _sessionService = _interopRequireDefault(require("~/app/3x/modules/services/session-service"));

var _authService = _interopRequireDefault(require("~/app/3x/modules/services/auth-service"));

var _routeService = _interopRequireDefault(require("~/app/3x/modules/services/route-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'sessionStore';
/**
	 * This saga should be triggered when a user's session expires
	 */

/* eslint-disable-next-line require-yield */

exports.STORE_NAME = STORE_NAME;

function* clearSession() {
  _authService.default.removeToken();
}
/**
	 * This saga is triggered when a user logs or relogs.
	 * The main edge case to beware of here is if a different user
	 * relogs, we need to dispatch a reset store action to clear all caches.
	 */


function* login(_ref) {
  var {
    payload: credentials
  } = _ref;

  try {
    var lastUserEmail = yield (0, _effects.select)(state => state[STORE_NAME].lastUserEmail);
    yield (0, _effects.put)(internalActions.loginRequest());
    var session = yield _authService.default.login(credentials);
    (0, _apiService.setupAPI)(session, true); // If the last session's email is different then this session's email
    // Dispatch a reset stores action to clear our cache, and reload
    // the page to make sure data isn't partially loaded and permissions
    // are fully rechecked. (Yes we can technically skip the reset stores because of the hard refresh)

    if (lastUserEmail && lastUserEmail !== session.emailNormal) {
      yield (0, _effects.put)(internalActions.resetStores());
      window.location.reload();
      return;
    }

    yield (0, _effects.put)(internalActions.loginSuccess(session));

    if (credentials.isTwoFactor) {
      _routeService.default.navigateTo('projects');
    }

    return session;
  } catch (e) {
    var err = (0, _common.handleError)(e === 'access-denied' ? {
      message: 'Invalid username or password'
    } : e);
    yield (0, _effects.put)(internalActions.loginFailure(err));
    return err;
  }
}
/**
	 * This saga should be triggered when a user explicitly logs out
	 */


function* logout() {
  try {
    yield (0, _effects.put)(internalActions.logoutRequest());
    yield _authService.default.logout();
    yield (0, _effects.put)(internalActions.logoutSuccess());
    yield (0, _effects.put)(actions.clearSession());
    window.location.replace('/');
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.logoutFailure(err)); // Even if we fail, we can clear the cookies and reload the site
    // The session will be cleared from the computer and eventually timeout on the server side

    _authService.default.removeToken();

    window.location.replace('/');
    return err;
  }
}

function* resetPassword(_ref2) {
  var {
    payload: {
      email
    }
  } = _ref2;

  try {
    yield (0, _effects.put)(internalActions.resetPasswordRequest());
    var url = "".concat(window.location.origin, "/reset/:token");
    yield _sessionService.default.resetPassword({
      email,
      url
    });
    yield (0, _effects.put)(internalActions.resetPasswordSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.resetPasswordFailure(err));
    return err;
  }
}

function* twoFactorLogin(_ref3) {
  var {
    payload: {
      token
    }
  } = _ref3;

  try {
    yield (0, _effects.put)(internalActions.twoFactorLoginRequest());

    var session = _sessionService.default.twoFactorLogin({
      token
    });

    yield login({
      token: session === null || session === void 0 ? void 0 : session.token,
      username: session === null || session === void 0 ? void 0 : session.emailNormal
    });
    yield (0, _effects.put)(internalActions.twoFactorLoginSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.twoFactorLoginFailure(err));
    return err;
  }
}

function* fetchSession() {
  try {
    yield (0, _effects.put)(internalActions.getSessionRequest());
    var session = yield _sessionService.default.getSession();
    yield (0, _effects.put)(internalActions.getSessionSuccess(session));
    return session;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getSessionFailure(err));
    return err;
  }
}

var LOGIN = 'session.login';
exports.LOGIN = LOGIN;
var LOGIN_REQUEST = 'session.login.request';
exports.LOGIN_REQUEST = LOGIN_REQUEST;
var LOGIN_SUCCESS = 'session.login.success';
exports.LOGIN_SUCCESS = LOGIN_SUCCESS;
var LOGIN_FAILURE = 'session.login.failure';
exports.LOGIN_FAILURE = LOGIN_FAILURE;
var TWO_FACTOR_LOGIN = 'session.two-factor-login';
exports.TWO_FACTOR_LOGIN = TWO_FACTOR_LOGIN;
var TWO_FACTOR_LOGIN_REQUEST = 'session.two-factor-login.request';
exports.TWO_FACTOR_LOGIN_REQUEST = TWO_FACTOR_LOGIN_REQUEST;
var TWO_FACTOR_LOGIN_SUCCESS = 'session.two-factor-login.success';
exports.TWO_FACTOR_LOGIN_SUCCESS = TWO_FACTOR_LOGIN_SUCCESS;
var TWO_FACTOR_LOGIN_FAILURE = 'session.two-factor-login.failure';
exports.TWO_FACTOR_LOGIN_FAILURE = TWO_FACTOR_LOGIN_FAILURE;
var LOGOUT = 'session.logout';
exports.LOGOUT = LOGOUT;
var LOGOUT_REQUEST = 'session.logout.request';
exports.LOGOUT_REQUEST = LOGOUT_REQUEST;
var LOGOUT_SUCCESS = 'session.logout.success';
exports.LOGOUT_SUCCESS = LOGOUT_SUCCESS;
var LOGOUT_FAILURE = 'session.logout.failure';
exports.LOGOUT_FAILURE = LOGOUT_FAILURE;
var RESET_PASSWORD = 'session.reset-password';
exports.RESET_PASSWORD = RESET_PASSWORD;
var RESET_PASSWORD_REQUEST = 'session.reset-password.request';
exports.RESET_PASSWORD_REQUEST = RESET_PASSWORD_REQUEST;
var RESET_PASSWORD_SUCCESS = 'session.reset-password.success';
exports.RESET_PASSWORD_SUCCESS = RESET_PASSWORD_SUCCESS;
var RESET_PASSWORD_FAILURE = 'session.reset-password.failure';
exports.RESET_PASSWORD_FAILURE = RESET_PASSWORD_FAILURE;
var RESET_PASSWORD_CLEAR_FEEDBACK = 'session.reset-password.clear-feedback';
exports.RESET_PASSWORD_CLEAR_FEEDBACK = RESET_PASSWORD_CLEAR_FEEDBACK;
var FETCH_SESSION = 'session.fetch';
exports.FETCH_SESSION = FETCH_SESSION;
var FETCH_SESSION_REQUEST = 'session.fetch.request';
exports.FETCH_SESSION_REQUEST = FETCH_SESSION_REQUEST;
var FETCH_SESSION_SUCCESS = 'session.fetch.success';
exports.FETCH_SESSION_SUCCESS = FETCH_SESSION_SUCCESS;
var FETCH_SESSION_FAILURE = 'session.fetch.failure';
exports.FETCH_SESSION_FAILURE = FETCH_SESSION_FAILURE;
var RESUME_SESSION = 'session.resume';
exports.RESUME_SESSION = RESUME_SESSION;
var CLEAR_SESSION = 'session.clear';
exports.CLEAR_SESSION = CLEAR_SESSION;
var UPDATE_LAST_ATTEMPT = 'session.update.last-attempt';
exports.UPDATE_LAST_ATTEMPT = UPDATE_LAST_ATTEMPT;
var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var MANUAL_UPDATE_SESSION = 'session.manual-update';
exports.MANUAL_UPDATE_SESSION = MANUAL_UPDATE_SESSION;
var INITIAL_STATE = {
  isLoggingIn: false,
  isLoggingOut: false,
  isResetingPassword: false,
  isResetPasswordSuccess: false,
  session: null,
  lastAttemptedAt: null,
  lastUserEmail: null,
  loginError: null,
  resetPasswordError: null,
  isLoading: false,
  lastLoadedAt: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOGIN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingIn: true,
          loginError: null
        });
      }

    case LOGIN_SUCCESS:
      {
        var session = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingIn: false,
          session: session,
          lastAttemptedAt: new Date(),
          lastUserEmail: session.emailNormal,
          loginError: null
        });
      }

    case LOGIN_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingIn: false,
          lastAttemptedAt: new Date(),
          loginError: action.payload
        });
      }

    case TWO_FACTOR_LOGIN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingIn: true,
          loginError: null
        });
      }

    case TWO_FACTOR_LOGIN_SUCCESS:
      {
        var _session = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingIn: false,
          session: _session,
          twofactor: {
            enabled: true
          },
          lastAttemptedAt: new Date(),
          lastUserEmail: _session.emailNormal,
          loginError: null
        });
      }

    case TWO_FACTOR_LOGIN_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingIn: false,
          twofactor: {
            enabled: true
          },
          lastAttemptedAt: new Date(),
          loginError: action.payload
        });
      }

    case LOGOUT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingOut: true
        });
      }

    case LOGOUT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          session: null,
          isLoggingOut: false,
          lastUserEmail: null
        });
      }

    case LOGOUT_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoggingOut: false
        });
      }

    case RESET_PASSWORD_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isResetingPassword: true,
          resetPasswordError: null,
          resetPasswordSuccess: false
        });
      }

    case RESET_PASSWORD_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isResetingPassword: false,
          resetPasswordSuccess: true
        });
      }

    case RESET_PASSWORD_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isResetingPassword: false,
          resetPasswordError: action.payload
        });
      }

    case RESET_PASSWORD_CLEAR_FEEDBACK:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          resetPasswordError: null,
          resetPasswordSuccess: false
        });
      }

    case FETCH_SESSION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: true
        });
      }

    case FETCH_SESSION_SUCCESS:
      {
        var _session2 = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          session: _session2,
          isLoading: false,
          lastLoadedAt: new Date()
        });
      }

    case FETCH_SESSION_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false
        });
      }

    case RESUME_SESSION:
      {
        var _session3 = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          session: _session3,
          lastAttemptedAt: new Date(),
          lastUserEmail: _session3.emailNormal
        });
      }

    case UPDATE_LAST_ATTEMPT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          lastAttemptedAt: new Date()
        });
      }

    case CLEAR_SESSION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          session: null,
          loginError: null
        });
      }

    case MANUAL_UPDATE_SESSION:
      {
        var _session4 = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          session: _session4
        });
      }

    default:
      return state;
  }
}

var actions = {
  login: (0, _reduxActions.createAction)(LOGIN),
  logout: (0, _reduxActions.createAction)(LOGOUT),
  resetPassword: (0, _reduxActions.createAction)(RESET_PASSWORD),
  twoFactorLogin: (0, _reduxActions.createAction)(TWO_FACTOR_LOGIN),
  clearResetPasswordFeedback: (0, _reduxActions.createAction)(RESET_PASSWORD_CLEAR_FEEDBACK),
  getSession: (0, _reduxActions.createAction)(FETCH_SESSION),
  resumeSession: (0, _reduxActions.createAction)(RESUME_SESSION),
  updateLastAttempt: (0, _reduxActions.createAction)(UPDATE_LAST_ATTEMPT),
  clearSession: (0, _reduxActions.createAction)(CLEAR_SESSION),
  manualUpdateSession: (0, _reduxActions.createAction)(MANUAL_UPDATE_SESSION)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loginRequest: (0, _reduxActions.createAction)(LOGIN_REQUEST),
  loginSuccess: (0, _reduxActions.createAction)(LOGIN_SUCCESS),
  loginFailure: (0, _reduxActions.createAction)(LOGIN_FAILURE),
  twoFactorLoginRequest: (0, _reduxActions.createAction)(TWO_FACTOR_LOGIN_REQUEST),
  twoFactorLoginSuccess: (0, _reduxActions.createAction)(TWO_FACTOR_LOGIN_SUCCESS),
  twoFactorLoginFailure: (0, _reduxActions.createAction)(TWO_FACTOR_LOGIN_FAILURE),
  logoutRequest: (0, _reduxActions.createAction)(LOGOUT_REQUEST),
  logoutSuccess: (0, _reduxActions.createAction)(LOGOUT_SUCCESS),
  logoutFailure: (0, _reduxActions.createAction)(LOGOUT_FAILURE),
  resetPasswordRequest: (0, _reduxActions.createAction)(RESET_PASSWORD_REQUEST),
  resetPasswordSuccess: (0, _reduxActions.createAction)(RESET_PASSWORD_SUCCESS),
  resetPasswordFailure: (0, _reduxActions.createAction)(RESET_PASSWORD_FAILURE),
  getSessionRequest: (0, _reduxActions.createAction)(FETCH_SESSION_REQUEST),
  getSessionSuccess: (0, _reduxActions.createAction)(FETCH_SESSION_SUCCESS),
  getSessionFailure: (0, _reduxActions.createAction)(FETCH_SESSION_FAILURE),
  resetStores: (0, _reduxActions.createAction)(RESET_STORES)
};
var selectors = {
  getSession: (0, _common.createSelector)(STORE_NAME, getSession),
  getStudioLogo: (0, _common.createSelector)(STORE_NAME, getStudioLogo),
  isLoggedIn: (0, _common.createSelector)(STORE_NAME, isLoggedIn),
  isLoggingIn: (0, _common.createSelector)(STORE_NAME, isLoggingIn),
  isLoggingOut: (0, _common.createSelector)(STORE_NAME, isLoggingOut),
  isResetingPassword: (0, _common.createSelector)(STORE_NAME, isResetingPassword),
  isResetPasswordSuccess: (0, _common.createSelector)(STORE_NAME, isResetPasswordSuccess),
  getLastAttemptedAt: (0, _common.createSelector)(STORE_NAME, getLastAttemptedAt),
  getLoginError: (0, _common.createSelector)(STORE_NAME, getLoginError),
  getTwoFactorLogin: (0, _common.createSelector)(STORE_NAME, getTwoFactorLogin),
  getResetPasswordError: (0, _common.createSelector)(STORE_NAME, getResetPasswordError),
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  isLoaded: (0, _common.createSelector)(STORE_NAME, isLoaded),
  getLastLoadedAt: (0, _common.createSelector)(STORE_NAME, getLastLoadedAt)
};
exports.selectors = selectors;

function getSession(state) {
  return state.session;
}

function getStudioLogo(state) {
  var _state$session, _state$session$studio;

  if (((_state$session = state.session) === null || _state$session === void 0 ? void 0 : (_state$session$studio = _state$session.studios) === null || _state$session$studio === void 0 ? void 0 : _state$session$studio.length) === 1) {
    var _state$session2;

    return (_state$session2 = state.session) === null || _state$session2 === void 0 ? void 0 : _state$session2.studios[0].$links.logo;
  }

  return '/img/pixwel.logo.png';
}

function isLoggedIn(state) {
  var _state$session3;

  return !!state.session && !((_state$session3 = state.session) !== null && _state$session3 !== void 0 && _state$session3.twofactor);
}

function isLoggingIn(state) {
  return state.isLoggingIn;
}

function isResetingPassword(state) {
  return state.isResetingPassword;
}

function isResetPasswordSuccess(state) {
  return state.resetPasswordSuccess;
}

function isLoggingOut(state) {
  return state.isLoggingOut;
}

function getLastAttemptedAt(state) {
  return state.lastAttemptedAt;
}

function getLoginError(state) {
  return state.loginError;
}

function getTwoFactorLogin(state) {
  var _state$session4;

  return (_state$session4 = state.session) === null || _state$session4 === void 0 ? void 0 : _state$session4.twofactor;
}

function getResetPasswordError(state) {
  return state.resetPasswordError;
}

function isLoading(state) {
  return state.isLoading;
}

function isLoaded(state) {
  return !!state.lastLoadedAt;
}

function getLastLoadedAt(state) {
  return state.lastLoadedAt;
}

function* watchSession() {
  yield (0, _effects.takeLatest)(LOGIN, login);
  yield (0, _effects.takeLatest)(TWO_FACTOR_LOGIN, twoFactorLogin);
  yield (0, _effects.takeLatest)(LOGOUT, logout);
  yield (0, _effects.takeLatest)(CLEAR_SESSION, clearSession);
  yield (0, _effects.takeLatest)(RESET_PASSWORD, resetPassword);
  yield (0, _effects.takeLatest)(FETCH_SESSION, fetchSession);
}