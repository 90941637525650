"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _moment = _interopRequireDefault(require("moment"));

var _embargoService = _interopRequireDefault(require("./embargo-service"));

var _apiService = _interopRequireWildcard(require("./api-service"));

var _transferManager = _interopRequireDefault(require("./transfer-manager"));

var _sessionService = _interopRequireDefault(require("./session-service"));

var _eventService = _interopRequireDefault(require("./event-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var TAG_TRANSFORMS = {
  texted: 'txtd',
  textless: 'txtl',
  subbed: 'sub',
  subtitle: 'sub',
  subtitled: 'sub',
  dubbed: 'dub'
};
var FILES_QUERY = "\nquery getFiles($verified: Boolean, $offset: Int, $limit: Int, $studio: String, \n$project: String, $asset: [String], $language: String) {\n  files(verified: $verified, offset: $offset, limit: $limit, studio: $studio, \n  project: $project, asset: $asset, language: $language) {\n    nodes {\n      id\n      name\n      language\n      verified\n      project {\n        name\n        id\n      }\n      asset {\n        id\n        name\n        type\n        length\n      }\n      embargo\n    }\n    totalCount\n  }\n}";
var FileService = {
  getAllFiles(_ref) {
    var {
      asset,
      language
    } = _ref;
    var params = {
      asset,
      language
    };
    return _apiService.default.client.get((0, _apiService.buildURL)('/files', params), {
      headers: {
        'X-Range': 'resources=0-200'
      }
    }).then(res => res.data);
  },

  getFiles(params) {
    var {
      page = 1,
      limit = 9
    } = params;
    return _apiService.default.graphql.request(FILES_QUERY, _objectSpread({
      offset: (page - 1) * limit,
      limit,
      language: '_all'
    }, params));
  },

  /**
   * Returns an object of unlocked and locked files grouped by their embargo status.
   *
   * The main object that is returned contains two keys:
   * All files that are currently unlocked with be nested under the "unlocked" key.
   * All files that are currently locked with be nested under the "locked" key.
   *
   * Under each of these keys files will be grouped by their relock date (for unlocked files),
   * or their unlock date (for locked files). Files that are permanently unlocked will be grouped
   * under the key: 0. Files that are permanently locked will be grouped under the key: Infinity.
   * These keys were chosen to make sorting easier.
   *
   * Example response:
   * {
   *     unlocked: {
   *         0: [fileA, fileB], // these files are unlocked with no relock date
   *         1547414825: [fileC, fileD], // these files are unlocked until 1547414825000 (the milliseconds are truncated)
   *     },
   *     locked: {
   *         1547414825: [fileE, fileF], // these files are locked until 1547414825000 (the milliseconds are truncated)
   *         Infinity: [fileG, fileH], // these files are locked with no unlock date (both the unlock and relock date have passed)
   *     }
   * }
   *
   * @param files: AssetFile[] The files to group by embargo status
   * @param when: Moment The date to use when calculating a file's embargo status.
   *        Defaults to the time when the function was invoked
   * @returns {Object}
   */
  groupFilesByEmbargoStatus(files) {
    var when = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _moment.default)();
    var fileGroups = {
      unlocked: {},
      locked: {}
    };

    _lodash.default.forEach(files, file => {
      var embargoStatus = _embargoService.default.getEmbargoStatus(file, when);

      if (!embargoStatus.isLocked) {
        var embargoStart = (0, _lodash2.default)(file, 'embargo.start', 0);
        var key = embargoStatus.willLock ? embargoStart : 0;

        if (!(embargoStart in fileGroups.unlocked)) {
          fileGroups.unlocked[key] = [];
        }

        fileGroups.unlocked[key].push(file);
      } else {
        var embargoEnd = (0, _lodash2.default)(file, 'embargo.end', 0);

        var _key = embargoStatus.willUnlock ? embargoEnd : Infinity;

        if (!(embargoEnd in fileGroups.locked)) {
          fileGroups.locked[_key] = [];
        }

        fileGroups.locked[_key].push(file);
      }
    });

    return fileGroups;
  },

  filterUnlockedFiles(files) {
    var when = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _moment.default)();
    return _lodash.default.filter(files, file => {
      var embargoStatus = _embargoService.default.getEmbargoStatus(file, when);

      return !embargoStatus.isLocked;
    });
  },

  filterLockedFiles(files) {
    var when = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _moment.default)();
    return _lodash.default.filter(files, file => {
      var embargoStatus = _embargoService.default.getEmbargoStatus(file, when);

      return embargoStatus.isLocked;
    });
  },

  hasBeenDownloaded(file) {
    return (0, _lodash2.default)(file, '$aspera.downloaded', false);
  },

  getFileName(file) {
    var _file$split;

    return (file === null || file === void 0 ? void 0 : (_file$split = file.split('/')) === null || _file$split === void 0 ? void 0 : _file$split.pop()) || '';
  },

  searchFileName(file, queryWords) {
    var fileName = file.name.toLowerCase();

    for (var word of queryWords) {
      word = word.toLowerCase();

      if (fileName.includes(word)) {
        return true;
      }
    }

    return false;
  },

  searchFileTags(file, queryWords) {
    for (var word of queryWords) {
      word = word.toLowerCase();
      var transformedWord = TAG_TRANSFORMS[word];
      var tags = file.tags || [];

      for (var tag of tags) {
        tag = tag.toLowerCase(); // If the search word matches a tag exactly, or the transformed words, keep the file

        if (word === tag || transformedWord === tag) {
          return true;
        }
      }
    }

    return false;
  },

  matchShareTags(file, shareTags) {
    var intersection = shareTags.filter(tag => {
      var _file$tags;

      return (_file$tags = file.tags) === null || _file$tags === void 0 ? void 0 : _file$tags.some(t => t.includes(tag));
    });
    return !!intersection.length;
  },

  downloadFile(file) {
    return _transferManager.default.add([file]);
  },

  formatBytes(bytes) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
      return '-';
    }

    if (bytes === 0) {
      return '0 bytes';
    }

    var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
  },

  downloadStandardFile(fileId) {
    var link = document.createElement('a');
    link.target = '_blank';
    return _apiService.default.client.get("/files/".concat(fileId), {
      headers: {
        'Accept': 'application/json, text/plain, */*'
      }
    }).then(res => {
      return _apiService.default.client.get("/files/".concat(fileId), {
        headers: {
          'Accept': '*/*'
        }
      }).then(file => {
        link.download = res.data.name;
        link.href = URL.createObjectURL(new Blob([file.data], {
          type: res.data.mime
        }));
        link.click();
      });
    });
  },

  downloadContinuityScript: function () {
    var _downloadContinuityScript = (0, _asyncToGenerator2.default)(function* (scriptId) {
      var session = yield _sessionService.default.getSession();

      _eventService.default.createEvent(session._id, [scriptId], 'sd');

      var event = document.createEvent('MouseEvents');
      var link = document.createElement('a');
      return _apiService.default.client.get("/files/".concat(scriptId), {
        headers: {
          'Accept': 'application/json, text/plain, */*'
        }
      }).then(result => {
        fetch(result.config.baseURL + result.config.url).then(r => r.blob()).then(res => {
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.addEventListener('load', () => {
            link.href = reader.result;
            link.download = result.data.name;
            event.initEvent('click', true, true);
            link.dispatchEvent(event);
          });
        });
      });
    });

    function downloadContinuityScript(_x) {
      return _downloadContinuityScript.apply(this, arguments);
    }

    return downloadContinuityScript;
  }(),
  downloadGraphicsProject: url => {
    var link = document.createElement('a');
    link.target = '_blank';
    link.download = 'download';
    link.href = url;
    link.click();
  },

  uploadAssetFile(_ref2) {
    var {
      file,
      onUploadProgress
    } = _ref2;
    return _apiService.default.client.post('/files', file, {
      headers: {
        'X-File-Name': file.name,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    }).then(res => {
      return res.data._id;
    });
  },

  uploadImageFile(file) {
    return _apiService.default.client.post('/files', file, {
      headers: {
        'X-File-Name': file.name,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/octet-stream'
      }
    }).then(res => {
      return res.data._id;
    });
  },

  deleteFile(id) {
    return _apiService.default.client.delete("/files/".concat(id)).catch(console.error);
  },

  updateFile(id, data) {
    return _apiService.default.client.patch("/files/".concat(id), data).catch(console.error);
  }

};
var _default = FileService;
exports.default = _default;